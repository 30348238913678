.App {
  text-align: center;

  align-items: center;
}

.disable-app {
  text-align: center;
  opacity: .3;
  background-color: #c1c1c1;
  z-index: 1000;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;

}

.disable-control {
    width: 100%;
    height: 10%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    opacity: 0.7;
    background-color:#c1c1c1;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
#circle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(228, 182, 238);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed; /* change to fixed */
  transform: translate(-50%, -50%);
  z-index: 9999; /* add z-index */
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.copy-link {
  height: 70px;
  width: 80px;
  position: absolute;
  bottom: 110px;
  right: 0px;
  color: black;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
  align-items: center;
  justify-content: space-evenly;
  /* background-color: blue; */
}

.fixed-controls {
  position: fixed;
  bottom: 0;
  background-color: #e7e7e7;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transform: translateX(0%);
}

.controls-container {
  background-color: #f7f2f2;
  width: 100%;
  height: 10%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* backgroundColor:"red",width:"100%",height:"90px",position:"absolute",bottom:"0",display:"flex", flexDirection:"row",justifyContent:"center",alignItems:"center" */
}

.dot1 {
  animation: visibility 3s linear infinite;
 }
 @keyframes visibility {
  0% {
  opacity: 1;
  }
  65% {
  opacity: 1;
  }
  66% {
  opacity: 0;
  }
  100% {
  opacity: 0;
  }
 }

 .dot2 {
  animation: visibility2 3s linear infinite;
 }
 @keyframes visibility2 {
  0% {
   opacity: 0;
  }
  21% {
   opacity: 0;
  }
  22% {
   opacity: 1;
  }
  65% {
   opacity: 1;
  }
  66% {
   opacity: 0;
  }
  100% {
   opacity: 0;
  }
 }

 .dot3 {
  animation: visibility3 3s linear infinite;
 }
 @keyframes visibility3 {
  0% {
   opacity: 0;
  }
  43% {
   opacity: 0;
  }
  44% {
   opacity: 1;
  }
  65% {
   opacity: 1;
  }
  66% {
   opacity: 0;
  }
  100% {
   opacity: 0;
  }
 }

.blink-text {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.react-responsive-modal-container{
  display: flex;
  align-items: center;

  .react-responsive-modal-modal {
    border-radius: 10px;
  }

  .modal-text {
    margin-top: 20px;
  }

  .modal-actions {
    width: 100%;
    justify-content: center;
    display: flex;

    .btn {
      border: 1px solid #c2c2c2;
      width: 100px;
    }
  }
}

.bg-aidac{
  background-color: #008080!important;
}
